<template>
  <div v-if="card" class="page-content">
    <page-breadcrumb title="Grammar Card" class="mb-2" :items="breadcrumbItems" />
    <section class="mb-2">
      <b-card class="mt-1">
        <form-generator :model="card" :schema="card_form_schema" />
      </b-card>
      <h3 class="h2 mt-3">Examples</h3>
      <dynamic-zone ref="examples" item-collapse="last" :items="card.examples"
        :computeTitleFn="(example) => { return example.text.translations.find(item => item.language === 'en').value || example._id }"
        :default-data="{ text: JSON.parse(JSON.stringify(translatableTextDefault)), audio: null }">
        <template v-slot="{ item: example }">
          <form-generator :model="example" :schema="example_form_schema" />
        </template>
      </dynamic-zone>
    </section>
    <action-buttons update-button back-button delete-button @update="update" :is-updating="isUpdating"
      @delete="deleteItem" :is-deleting="isDeleting" />
  </div>
</template>
<script>
import service from '../service';
import translatableTextDefault from '@core/layouts/components/input-fields/translatable-text-field/defaultObject.js'
const card_form_schema = [
  {
    cols: 12,
    fields: [
      { label: 'Title', field: 'title', input_type: 'ui-component' },
      { label: 'Subtile', field: 'subtitle', input_type: 'ui-component' },
      { label: 'Description', field: 'description', input_type: 'ui-component' },
      { label: 'Image Url', field: 'imageUrl', input_type: 'ui-component' },
    ]
  }
];
const example_form_schema = [{
  cols: 12, fields: [
    { label: 'Text', field: 'text', input_type: 'ui-component' },
    { label: 'Audio', field: 'audio' }
  ],
}]
export default {
  data() {
    return {
      card_form_schema,
      example_form_schema,
      card: null,
      isUpdating: false,
      isDeleting: false,
      triggers: null,
      translatableTextDefault
    }
  },
  computed: {
    itemId() {
      return this.$route.params.id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: 'Grammar Cards',
          to: { name: 'grammar-card-list' },
        },
        {
          text: `${this.card ? this.card.name : this.itemId}`, active: true
        },
      ]
      return items
    },
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  created() {
    this.getCard();
  },
  methods: {
    async getCard() {
      this.card = await service.get({ id: this.itemId });
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.card),
      });
      this.getCard();
      this.isUpdating = false;
    },
    async deleteItem() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "grammar-card-list" });
    },
  }
}
</script>
