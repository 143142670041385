import api from "@/libs/axios";

export default {
  async getList(params) {
    let response = await api.get("/admin/grammar-cards", {
      params
    });
    return response;
  },

  async get(params) {
    let response = await api.get("/admin/grammar-cards/detail", {
      params
    });
    return response.data.data.document || null;
  },

  async create(payload) {
    const response = await api.post("/admin/grammar-cards", payload);
    return response || null;
  },

  async update(payload) {
    const response = await api.put("/admin/grammar-cards", payload);
    return response || null;
  },

  async delete(payload) {
    const response = await api.delete("/admin/grammar-cards", {
      data: payload
    });
    return response;
  }
};
